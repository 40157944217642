import {
	clearCurrentTree,
	clearRehabTreesDefault,
	getRehabTreesList,
} from '@Actions/Rehab.actions';
import { selectIsLoading } from '@Selectors/Rehab.selectors';
import { FullPage, FullPageWrapper } from '@Utils/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import RehabTreeList from '@Components/RehabTreeList';
import { useGlobalization } from '@Translations';

const RehabManagement = () => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	// const rehabTrees = selectRehabTrees();

	useEffect(() => {
		dispatch(clearCurrentTree());
		dispatch(clearRehabTreesDefault());
		dispatch(getRehabTreesList());
		// if (rehabTrees === undefined || rehabTrees.length === 0) {
		// 	dispatch(clearRehabTreesDefault());
		// }
		// const bNeedGetTrees = rehabTrees === undefined || rehabTrees.length === 0;
		// dispatch(getRehabTreesList(bNeedGetTrees));
	}, []);

	const isLoading = selectIsLoading();

	const rehabTreeListTab = () => {
		return (
			<FullPage>
				<RehabTreeList />
			</FullPage>
		);
	};

	const secondaryNav = () => {
		const tabs = [
			{ content: rehabTreeListTab(), title: t('Decision Trees') },
		];

		return tabs;
	};

	return (
		<FullPageWrapper
			isLoading={isLoading}
			title={t('Rehab Decision Tree')}
			applyPadding={true}
			headerUnderline
			secondaryNav={{
				items: secondaryNav(),
				itemIndex: 0,
			}}></FullPageWrapper>
	);
};
export default RehabManagement;
