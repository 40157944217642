import {
	CardContent,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import {
	selectRefreshList,
	selectRehabTreesIsCreating,
	selectRehabTreesIsDeleting,
	selectRehabTreesIsLoading,
	selectRehabTreesIsWaiting,
	selectRehabTreesPage,
	selectRehabTreesPagination,
	selectRehabTreesPerPage,
} from '@Selectors/Rehab.selectors';
import React, { useEffect, useState } from 'react';
import RehabTreeCard from './RehabTreeCard.component';
import { useDispatch } from 'react-redux';
import {
	changeRehabTreesPage,
	changeRehabTreesSlice,
	changeRehabTreesSort,
	deleteRehabTree,
	setRefreshList,
} from '@Actions';
import {
	DropDownButton,
	getSubscriptions,
	WarningDialog,
	Text,
	rem,
} from '@innovyze/stylovyze';
import {
	FlexStyledGrid,
	NewTreeCardWrapper,
	StyledPagination,
	StyledText,
} from './RehabTreeCard.style';
import { fullAccess } from '@innovyze/shared-utils';
import NewTree from '@Components/Utils/icons/NewTree';
import { useGlobalization } from '@Translations';
import { FullPage } from '@Utils/styles';
import { Refresh } from '@mui/icons-material';
import { RehabCard } from '@Types/rehab.types';
import { NewRehab } from '@Components/NewRehab';

const RehabTreeList = () => {
	const { t } = useGlobalization();
	const subscriptions = getSubscriptions();
	const hasFullAccess = fullAccess(subscriptions);

	const dispatch = useDispatch();
	const [openDelete, setOpenDelete] = useState(false);
	const [treeId, setTreeId] = useState('');
	const rehabPage = selectRehabTreesPage();
	const pagination = selectRehabTreesPagination();
	const isLoading = selectRehabTreesIsLoading();
	const isWaiting = selectRehabTreesIsWaiting();
	const isCreating = selectRehabTreesIsCreating();
	const isDeleting = selectRehabTreesIsDeleting();
	const [newTree, setNewTree] = useState(false);
	const refreshList = selectRefreshList();

	const dispatchSliceFn = (slice: number) =>
		dispatch(changeRehabTreesSlice(slice));
	const rowsPerPage = selectRehabTreesPerPage(dispatchSliceFn);

	const sortOptions = [
		{ id: 'updatedAt-D', caption: 'Date Created/Modified - Descending' },
		{ id: 'updatedAt-A', caption: 'Date Created/Modified - Ascending' },
		{ id: 'name-D', caption: 'Name - Descending' },
		{ id: 'name-A', caption: 'Name - Ascending' },
		{ id: 'lastRun-D', caption: 'Last Run - Descending' },
		{ id: 'lastRun-A', caption: 'Last Run - Ascending' },
		{ id: 'runBy-D', caption: 'Run By - Descending' },
		{ id: 'runBy-A', caption: 'Run By - Ascending' },
	];
	const [selectedSort, setSelectedSort] = useState('updatedAt-D');

	const options = sortOptions.map(idCaption => {
		return (
			<MenuItem value={idCaption.id} key={idCaption.id}>
				{idCaption.caption}
			</MenuItem>
		);
	});

	const onRefresh = () => {
		dispatch(changeRehabTreesPage(rehabPage.page));
	};

	useEffect(() => {
		if (refreshList) {
			onRefresh();
			dispatch(setRefreshList(false));
		}
		if (pagination && pagination.sortKey) {
			setSelectedSort(
				`${pagination.sortKey}-${
					pagination.sortDescending ? 'D' : 'A'
				}`,
			);
		}
	}, []);

	useEffect(() => {
		if (pagination && pagination.sortKey) {
			setSelectedSort(
				`${pagination.sortKey}-${
					pagination.sortDescending ? 'D' : 'A'
				}`,
			);
		}
	}, []);

	const handleDeleteConfirmYes = () => {
		setOpenDelete(false);
		dispatch(
			deleteRehabTree({
				treeId: treeId,
				refresh: onRefresh,
			}),
		);
		setTreeId('');
	};

	const handleDeleteConfirmNo = () => {
		setOpenDelete(false);
		setTreeId('');
	};

	const handleDeleteTree = (id: string) => {
		setTreeId(id);
		setOpenDelete(true);
	};

	const onCreateNewTree = () => {
		if (!(isLoading || isWaiting || isDeleting || isCreating))
			setNewTree(true);
	};

	const onNewTreeClose = () => {
		setNewTree(false);
	};

	const sortChange = (event: SelectChangeEvent<typeof selectedSort>) => {
		const target = event?.target as HTMLInputElement;
		const sort = target.value.split('-');
		dispatch(
			changeRehabTreesSort({
				key: sort[0],
				descending: sort[1] === 'D',
			}),
		);
		setSelectedSort(target.value);
	};

	const createNewTreeCard = () => {
		return (
			<NewTreeCardWrapper>
				<CardContent style={{ height: '100%' }}>
					<div
						role="button"
						onClick={() => onCreateNewTree()}
						onKeyDown={() => onCreateNewTree()}
						tabIndex={0}
						className="tree-create_card__icon">
						<NewTree />
						<div className="tree-create_card__note">
							<Typography gutterBottom variant="subtitle1">
								{t('Create New Decision Tree')}
							</Typography>
						</div>
					</div>
				</CardContent>
			</NewTreeCardWrapper>
		);
	};
	return (
		<FullPage
			className={
				isLoading || isWaiting || isDeleting || isCreating ? 'wait' : ''
			}>
			<WarningDialog
				open={openDelete}
				title={t('Delete Decision Tree?')}
				content={t(
					'Are you sure you want to delete this Decision Tree?',
				)}
				cancelText={t('Cancel')}
				cancelButtonProps={{
					disabled: isDeleting,
					dataCy: 'delete-cancel',
				}}
				confirmText={t('Delete')}
				confirmButtonProps={{
					disabled: isDeleting,
					dataCy: 'delete-confirm',
				}}
				onCancel={handleDeleteConfirmNo}
				onConfirm={handleDeleteConfirmYes}
			/>
			<NewRehab open={newTree} onClose={onNewTreeClose} />
			<Grid container spacing={2} direction="row">
				<Grid item xs={10} sm={6} md={4} lg={3} xl={2}>
					<FormControl fullWidth variant="standard">
						<InputLabel>{t('Sort By')}</InputLabel>
						<Select
							data-cy="sort-by"
							value={selectedSort}
							disabled={
								isLoading ||
								isWaiting ||
								isDeleting ||
								isCreating
							}
							name={t('Sort By')}
							onChange={sortChange}>
							{options}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					xs={2}
					sm={6}
					md={8}
					lg={9}
					xl={10}
					container
					justifyContent="flex-end"
					data-cy="grid-actionbutton">
					<IconButton
						disabled={
							isLoading || isWaiting || isDeleting || isCreating
						}
						title={t('Refresh')}
						data-cy="refreshButton"
						onClick={onRefresh}>
						<Refresh />
					</IconButton>
				</Grid>
				{hasFullAccess ? (
					<Grid xs={12} sm={6} md={4} lg={3} xl={3} item>
						{createNewTreeCard()}
					</Grid>
				) : (
					<></>
				)}
				{rehabPage?.cards.map((rehabTree: RehabCard) => (
					<Grid
						xs={12}
						sm={6}
						md={4}
						lg={3}
						xl={3}
						item
						key={rehabTree._id}>
						<RehabTreeCard
							rehabTree={rehabTree}
							onDeleteTree={handleDeleteTree}
							onRefresh={onRefresh}
							disabled={
								isLoading ||
								isWaiting ||
								isDeleting ||
								isCreating
							}
						/>
					</Grid>
				))}
				{!isLoading ? (
					<Grid
						item
						container
						xs={12}
						style={{
							padding: `${rem(16)} ${rem(24)}`,
						}}>
						<Grid item xs={6}>
							<Text variant="text-large">
								{t('Trees per page:')}
							</Text>
							<DropDownButton
								arrow
								color="secondary"
								options={rowsPerPage.options}
								placement="bottom-start"
								variant="text"
								initialSelectedIndex={rowsPerPage.selectedIndex}
							/>
						</Grid>
						<Grid item xs={6}>
							{rehabPage.pages > 1 ? (
								<FlexStyledGrid>
									<StyledPagination
										count={pagination.pages}
										page={pagination.page}
										onChange={(_e, page: number) => {
											dispatch(
												changeRehabTreesPage(page),
											);
										}}
										variant="outlined"
									/>
									<StyledText variant="caption-small">
										{t(
											'Showing {{from}}-{{to}} of {{total}} Decision Trees',
											{
												from:
													(pagination.page - 1) *
														pagination.slice +
													1,
												to:
													pagination.page ==
													pagination.pages
														? pagination.total
														: pagination.page *
														  pagination.slice,
												total: pagination.total,
											},
										)}
									</StyledText>
								</FlexStyledGrid>
							) : (
								[]
							)}
						</Grid>
					</Grid>
				) : (
					[]
				)}
			</Grid>
		</FullPage>
	);
};
export default RehabTreeList;
