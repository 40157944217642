import React from 'react';
import { useHistory } from 'react-router-dom';
import { addRouteNamespace } from '@Utils/actions';
import {
	getSubscriptions,
	MenuItem,
	PropertyCard,
	success,
} from '@innovyze/stylovyze';
import {
	AddToPhotos,
	Delete,
	Visibility,
	VisibilityOff,
	CheckCircle,
	RemoveCircle,
} from '@mui/icons-material';
import { RehabTreePublishStateType } from '@innovyze/lib_am_common';
import { formatDateTimeWithFormatDate } from '@innovyze/lib_am_common/Utils';
import { toggleRehabTreePublishState } from '@innovyze/lib_am_common/Actions';
import './tree-card.css';
import { fullAccess } from '@innovyze/shared-utils';
import { duplicateRehabTree } from '@Actions';
import { useDispatch } from 'react-redux';
import { useGlobalization } from '@Translations';
import { RehabCard } from '@Types/rehab.types';
import { Link, Tooltip } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { isFeatureEnabled, useCompanyDateTime } from '@innovyze/stylovyze';

interface Props {
	rehabTree: RehabCard;
	onDeleteTree: (id: string) => void;
	disabled: boolean;
	onRefresh: () => void;
}

const RehabTreeCard = ({
	rehabTree,
	onDeleteTree,
	disabled,
	onRefresh,
}: Props) => {
	const { t } = useGlobalization();
	const { _id, name, publishState } = rehabTree;
	const dispatch = useDispatch();
	const history = useHistory();
	const subscriptions = getSubscriptions();
	const hasFullAccess = fullAccess(subscriptions);
	const isPublishRehabTreeFeatureEnabled = isFeatureEnabled(
		'info360-publish-rehab-trees',
	);
	const { formatDate } = useCompanyDateTime();

	const handleViewClick = () => {
		if (!disabled) history.push(addRouteNamespace(`details/${_id}`));
	};

	const handleDeleteClick = () => {
		if (!disabled) onDeleteTree(_id);
	};

	const onDuplicateComplete = (treeId: string) => {
		if (treeId) {
			history.push(addRouteNamespace(`details/${treeId}`));
		}
	};

	const handleDuplicateClick = () => {
		if (!disabled)
			dispatch(
				duplicateRehabTree({
					treeId: _id,
					onComplete: onDuplicateComplete,
				}),
			);
	};

	const notifyRehabTreeStateChange = (
		newPublishState: RehabTreePublishStateType,
	) => {
		dispatch(
			success(
				t(`Rehab Tree ${newPublishState}.`, {
					newPublishState,
				}),
			),
		);
		onRefresh();
	};

	const handlePublishToggle = (
		newPublishState: RehabTreePublishStateType,
	) => {
		dispatch(
			toggleRehabTreePublishState({
				treeId: rehabTree._id,
				cb: () => notifyRehabTreeStateChange(newPublishState),
				newPublishState,
			}),
		);
	};

	const formatRunBy = (runBy: string) => {
		if (runBy.indexOf('@') !== -1) {
			return runBy.substring(0, runBy.lastIndexOf('@'));
		}
		return runBy;
	};

	const menuItems = () => {
		const items: MenuItem[] = [];

		if (hasFullAccess) {
			items.push({
				onClick: handleDuplicateClick,
				text: t('Duplicate'),
				icon: <AddToPhotos />,
			});
			items.push({
				onClick: handleDeleteClick,
				text: t('Delete'),
				icon: <Delete />,
			});
			if (isPublishRehabTreeFeatureEnabled) {
				items.push({
					onClick: () => {
						if (rehabTree.publishState === 'published') {
							handlePublishToggle('unpublished');
						}
						if (rehabTree.publishState === 'unpublished') {
							handlePublishToggle('published');
						}
					},
					text:
						rehabTree.publishState === 'unpublished'
							? t('Publish')
							: t('Unpublish'),
					icon:
						rehabTree.publishState === 'unpublished' ? (
							<Visibility />
						) : (
							<VisibilityOff />
						),
				});
			}
		}
		return items;
	};

	const renderHeader = () => {
		const tooltipText =
			publishState === 'published' ? t('Published') : t('Unpublished');

		const icon =
			publishState === 'published' ? (
				<CheckCircle sx={{ color: green[500] }} />
			) : (
				<RemoveCircle sx={{ color: grey[500] }} />
			);

		const publishStateTooltip = (
			<Tooltip title={tooltipText}>{icon}</Tooltip>
		);
		return (
			<div className="header-container">
				{renderTitle()} {publishStateTooltip}
			</div>
		);
	};

	const renderTitle = () => {
		if (
			hasFullAccess ||
			publishState === 'published' ||
			!isPublishRehabTreeFeatureEnabled
		) {
			return getTitle();
		}
		return (
			<div
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					width: '100%',
					whiteSpace: 'nowrap',
				}}>
				{name}
			</div>
		);
	};

	const getTitle = () => {
		return (
			<Link
				underline="hover"
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					cursor: 'pointer',
					width: '100%',
					whiteSpace: 'nowrap',
				}}
				title={name}
				onClick={() => {
					handleViewClick();
				}}
				data-cy="card-title">
				{name}
			</Link>
		);
	};

	const typeCell = (systemType?: string, assetType?: string) => {
		switch (systemType?.toLowerCase()) {
			case 'sanitary sewer':
				switch (assetType?.toLowerCase()) {
					case 'pipe':
						return t('Sanitary Sewer - Pipe');
					case 'manhole':
						return t('Sanitary Sewer - Manhole');
					default:
						return '-';
				}
			case 'water distribution':
				switch (assetType?.toLowerCase()) {
					case 'pipe':
						return t('Water Distibution - Pipe');
					default:
						return '-';
				}
		}
	};

	return (
		<div
			key={_id}
			style={{
				fontSize: '1rem',
			}}>
			<PropertyCard
				columns={3}
				forceMenu
				className="adjusted-menu-position-rehab"
				menuItems={menuItems()}
				properties={[
					{
						title: t('Last Run'),
						value: `${
							rehabTree.lastRun
								? formatDateTimeWithFormatDate(
										rehabTree.lastRun,
										formatDate,
								  )
								: '-'
						}`,
						ellipsis: true,
						span: 2,
					},
					{
						title: t('Status'),
						type: rehabTree.status ? 'status' : 'text',
						value: `${rehabTree.status ? rehabTree.status : '-'}`,
					},
					{
						title: t('Run By'),
						value: `${
							rehabTree.runBy ? formatRunBy(rehabTree.runBy) : '-'
						}`,
						ellipsis: true,
						span: 2,
					},
					{
						title: t('Node Count'),
						value: `${
							rehabTree.nodeCount ? rehabTree.nodeCount : '-'
						}`,
					},
					{
						title: t('System and Asset Type'),
						value: typeCell(
							rehabTree.systemType,
							rehabTree.assetType,
						),
						ellipsis: true,
						span: 2,
					},
					{
						title: t('Asset Count'),
						value: `${
							rehabTree.assetCount ? rehabTree.assetCount : '-'
						}`,
					},
				]}
				title={
					isPublishRehabTreeFeatureEnabled
						? renderHeader()
						: renderTitle()
				}
			/>
		</div>
	);
};
export default RehabTreeCard;
